import React from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';

const SummaryGenerator = ({ onOpenPricing }) => {
    const formFields = [
        LanguageSelect(false),
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <Generator
            title={<><span className="highlight">YT</span> to Summary</>}
            icon="bi bi-body-text icon"
            formFields={formFields}
            generateEndpoint="/generate/summary"
            transformFormData={(data) => ({
                url: data.url,
                language: data.language,
                model: data.model
            })}
            onOpenPricing={onOpenPricing}
            skipSubscriptionCheck={true}
        />
    );
};

export default SummaryGenerator; 