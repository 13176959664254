import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../api';

export const PricingContext = createContext();

export const PricingProvider = ({ children }) => {
    const [prices, setPrices] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await api.get('/pricing');
                setPrices(response.data.prices);
                setCurrency(response.data.currency);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPrices();
    }, []);

    const formatPrice = (amount) => {
        return new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currency || 'EUR'
        }).format(amount);
    };

    return (
        <PricingContext.Provider 
            value={{
                prices,
                currency,
                loading,
                error,
                formatPrice
            }}
        >
            {children}
        </PricingContext.Provider>
    );
};
export const usePricing = () => {
    const context = useContext(PricingContext);
    if (context === undefined) {
        throw new Error('usePricing must be used within a PricingProvider');
    }
    return context;
};
