// Sidebar.js
import React, { useContext } from 'react';
import './Sidebar.css'
import { Link } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContext';

const Sidebar = ({ onSelect, selected, onOpenPricing }) => {
    const { subscription, user } = useContext(AuthContext);

    return (
        <div className="side-menu">
            <img className='logo' src="/logo_light.png" alt="Logo" style={{ width: '80px', marginBottom: '30px' }} />
            <div className="nav">

                <button
                    className={`button-sidemenu ${selected === 'blog' ? 'active' : ''}`}
                    onClick={() => onSelect('blog')}
                >
                    <i className="fas fa-newspaper icon"></i>
                    YT to Blog
                </button>
                <button
                    className={`button-sidemenu ${selected === 'summary' ? 'active' : ''}`}
                    onClick={() => onSelect('summary')}
                >
                    <i className="fas fa-list icon"></i>
                    YT to Summary
                </button>
                <button
                    className={`button-sidemenu ${selected === 'tweet' ? 'active' : ''}`}
                    onClick={() => onSelect('tweet')}
                >
                    <i className="fab fa-twitter icon"></i>
                    YT to Tweet
                </button>
                <button
                    className={`button-sidemenu ${selected === 'newsletter' ? 'active' : ''}`}
                    onClick={() => onSelect('newsletter')}
                >
                    <i className="fas fa-envelope icon"></i>
                    YT to Newsletter
                </button>
                <button
                    className={`button-sidemenu ${selected === 'linkedin' ? 'active' : ''}`}
                    onClick={() => onSelect('linkedin')}
                >
                    <i className="fab fa-linkedin icon"></i>
                    YT to LinkedIn
                </button>
                <button
                    className={`button-sidemenu ${selected === 'custom' ? 'active' : ''}`}
                    onClick={() => onSelect('custom')}
                >
                    <i className="fas fa-magic icon"></i>
                    YT to Custom
                </button>
            </div>

            <div className="nav" style={{ marginTop: '40px' }}>

                <button
                    className={`button-sidemenu ${selected === 'generations' ? 'active' : ''}`}
                    onClick={() => onSelect('generations')}
                >
                    <i className="fas fa-history icon"></i>
                    My content
                </button>

                <button
                    className={`button-sidemenu ${selected === 'settings' ? 'active' : ''}`}
                    onClick={() => onSelect('settings')}
                >
                    <i className="fas fa-cog icon"></i>
                    Settings
                </button>


            </div>

            {!subscription?.active && !user?.byok && (
                <div className="premium-cta">
                    <h3>Access All Features</h3>
                    <p>Generate unlimited content with latest AI models</p>
                    <button className="button-important" onClick={onOpenPricing}>
                        <div className="inner">
                            <i className="fas fa-crown icon"></i>
                            Go Premium
                        </div>
                    </button>
                </div>
            )}

            {/* <div className="useful-links">
                <button className="button-plain" onClick={() => window.open("https://ytcopycat.canny.io", "_blank")}>
                    <i className="fas fa-comment-dots icon"></i>
                    Give feedback
                </button>
            </div> */}
        </div>
    );
};

export default Sidebar;
