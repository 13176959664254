import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Page/Navbar';
import Footer from '../Page/Footer';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '../UI/LoadingButton';
import LanguageSelect from '../App/Generator/LanguageSelect';
import FAQ from '../UI/FAQ';
import './PublicSummaryTool.css';

const PublicSummaryTool = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [hasUsedTool, setHasUsedTool] = useState(false);
    const [language, setLanguage] = useState('english');
    const [showSignupPrompt, setShowSignupPrompt] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const hasUsed = localStorage.getItem('hasUsedSummaryTool');
        if (hasUsed) {
            setHasUsedTool(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (hasUsedTool) {
            setShowSignupPrompt(true);
            return;
        }

        setLoading(true);
        setError('');
        setShowSignupPrompt(false);

        try {
            const response = await api.post('/public/generate/summary', {
                url: videoUrl,
                language
            });

            if (response.data.result === 'OK') {
                setSummary(response.data.text);
                localStorage.setItem('hasUsedSummaryTool', 'true');
                setHasUsedTool(true);
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            setError('An error occurred while summarizing the video.');
        } finally {
            setLoading(false);
        }
    };

    const formatContent = (content) => {
        return content.split('\n').map((line, i) => (
            <p key={i}>{line}</p>
        ));
    };

    const languageField = LanguageSelect(false, language, setLanguage);

    const faqQuestions = [
        {
            question: "Is this tool really free?",
            answer: "Yes, you can try our YouTube Video Summarizer completely free. After your first summary, you'll need to create an account to continue using the service.",
            icon: "fa-dollar-sign"
        },
        {
            question: "How accurate are the summaries?",
            answer: "Our AI-powered summarizer uses advanced language models to ensure high accuracy. The quality depends on the video's audio clarity and content structure.",
            icon: "fa-bullseye"
        },
        {
            question: "Are there any video length limitations?",
            answer: "There are no strict limitations on video length, as long as YouTube subtitles are available for processing.",
            icon: "fa-clock"
        },
        {
            question: "How can I use the summaries?",
            answer: "You can use the summaries for research, content creation, study notes, or quick understanding of video content. Simply copy and paste the summary wherever you need it.",
            icon: "fa-lightbulb"
        }
    ];

    return (
        <div className="yt-thumbnail-page">
            <Helmet>
                <title>Free AI YouTube Video Summarizer | YT Copycat</title>
                <meta name="description" content="Instantly summarize any YouTube video with AI. Save time with accurate, concise summaries - no registration required. Perfect for research, learning, and content creation." />
                <meta name="keywords" content="youtube video summarizer, summarize youtube video, youtube summarizer, youtube summary, ai youtube video summarizer, youtube video summary ai, youtube video summarizer ai, ai summarize youtube video" />
                <link rel="canonical" href="https://ytcopycat.com/youtube_summary" />
            </Helmet>

            <Navbar />
            
            <main className="yt-thumbnail-main">
                <div className="yt-thumbnail-container">
                    <div className="yt-thumbnail-header">
                        <h1>AI YouTube Video Summarizer</h1>
                        <p>Get straight to the key points of any YouTube video without spending hours watching. Our AI-powered tool generates accurate, concise summaries in seconds.</p>
                    </div>

                    <div className="features-grid">
                        <div className="feature-item">
                            <div className="icon">
                                <i className="fas fa-bolt"></i>
                            </div>
                            <div>
                                <h4>100% Free</h4>
                                <p>Summarize your first video completely free. No hidden fees or credit card required.</p>
                            </div>
                        </div>

                        <div className="feature-item">
                            <div className="icon">
                                <i className="fas fa-robot"></i>
                            </div>
                            <div>
                                <h4>AI-Powered Accuracy</h4>
                                <p>Advanced AI technology ensures precise and relevant summaries every time.</p>
                            </div>
                        </div>

                        <div className="feature-item">
                            <div className="icon">
                                <i className="fas fa-clock"></i>
                            </div>
                            <div>
                                <h4>Save Time</h4>
                                <p>Get key insights from long videos in seconds instead of hours.</p>
                            </div>
                        </div>
                    </div>

                    <div className="yt-thumbnail-form-container">
                        <form onSubmit={handleSubmit}>
                            <div className="yt-thumbnail-input-group">
                                <label htmlFor="videoUrl">YouTube Video URL</label>
                                <input
                                    type="text"
                                    id="videoUrl"
                                    className="yt-thumbnail-input"
                                    value={videoUrl}
                                    onChange={(e) => setVideoUrl(e.target.value)}
                                    placeholder="https://www.youtube.com/watch?v=..."
                                />
                            </div>

                            <div className="yt-thumbnail-input-group">
                                <label htmlFor={languageField.id}>{languageField.label}</label>
                                <select
                                    id={languageField.id}
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    className="yt-thumbnail-input"
                                >
                                    {languageField.options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <LoadingButton
                                type="submit"
                                loading={loading}
                                className="button-plain"
                            >
                                {loading ? 'Generating...' : 'Generate Summary'}
                            </LoadingButton>
                        </form>

                        {error && !hasUsedTool && (
                            <div className="error-message" style={{marginTop: '1rem'}}>
                                {error}
                            </div>
                        )}

                        {showSignupPrompt && (
                            <div className="signup-card">
                                <i className="fas fa-lock"></i>
                                <h3>Ready for More Summaries?</h3>
                                <p>You've discovered how powerful our AI summarizer is! Sign up now to:</p>
                                <ul>
                                    <li><i className="fas fa-check"></i> Summarize more videos for free</li>
                                    <li><i className="fas fa-check"></i> Access advanced AI models</li>
                                    <li><i className="fas fa-check"></i> Generate other content formats</li>
                                </ul>
                                <button 
                                    className="button-plain" 
                                    onClick={() => navigate('/signup')}
                                >
                                    Start Summarizing for Free
                                </button>
                            </div>
                        )}

                        {summary && (
                            <div className="yt-thumbnail-result">
                                <h2 className="yt-thumbnail-title">Video Summary</h2>
                                <div className="summary-content">
                                    {formatContent(summary)}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="section">
                        <div className="section-header">
                            <h4>PERFECT FOR</h4>
                            <h2>Who Can Benefit?</h2>
                            <p>Our tool is designed to help various users save time and increase productivity</p>
                        </div>
                        <div className="use-cases-list">
                            <div className="use-case-item">
                                <i className="fas fa-graduation-cap"></i>
                                <h3>Students & Researchers</h3>
                                <p>Quickly extract key information from educational videos and lectures.</p>
                            </div>
                            <div className="use-case-item">
                                <i className="fas fa-briefcase"></i>
                                <h3>Professionals</h3>
                                <p>Stay informed with concise summaries of industry talks and webinars.</p>
                            </div>
                            <div className="use-case-item">
                                <i className="fas fa-pen"></i>
                                <h3>Content Creators</h3>
                                <p>Generate quick summaries for your video descriptions and blog posts.</p>
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="section-header">
                            <h4>HOW IT WORKS</h4>
                            <h2>Get an AI YouTube Summary in 4 Easy Steps</h2>
                        </div>
                        <div className="steps-list">
                            <div className="step-item">
                                <div className="step-number">01</div>
                                <div className="step-content">
                                    <h3>Paste URL</h3>
                                    <p>Enter the YouTube video URL you want to summarize.</p>
                                </div>
                            </div>
                            <div className="step-item">
                                <div className="step-number">02</div>
                                <div className="step-content">
                                    <h3>AI Analysis</h3>
                                    <p>Our AI analyzes the video content and transcript.</p>
                                </div>
                            </div>
                            <div className="step-item">
                                <div className="step-number">03</div>
                                <div className="step-content">
                                    <h3>Generate Summary</h3>
                                    <p>Get a concise summary with key points in seconds.</p>
                                </div>
                            </div>
                            <div className="step-item">
                                <div className="step-number">04</div>
                                <div className="step-content">
                                    <h3>Save Time</h3>
                                    <p>Quickly grasp video content without watching the full video.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FAQ 
                        title="Frequently Asked Questions"
                        questions={faqQuestions}
                    />
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default PublicSummaryTool; 