import React from 'react';
import './LoadingButton.css'

const LoadingButton = ({ isLoading, onClick, disabled, children, className }) => {
    return (
        <button 
            type="submit"
            className={`${className || ''}`}
            onClick={onClick} 
            disabled={isLoading || disabled}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {isLoading ? (
                <span className="loader"></span>
            ) : (
                children
            )}
        </button>
    );
};

export default LoadingButton;
