import React from 'react';
import './AggressiveLandingPage.css';

const AggressiveConfirmationPage = () => {
    return (
        <div className="confirmation-page alp">
            {/* Hero Section */}
            <section className="alp-section">
                <div className="alp-container alp-text-center">
                    <h1>Congratulations!</h1>
                    <h2>
                        Your Access to <span className="alp-gradient-text">YT Copycat</span> is Confirmed
                    </h2>
                    
                    <div className="alp-banner">
                        <p><strong>Payment</strong> Confirmed | <strong>Access</strong> Immediate | <strong>Premium</strong> Support Included</p>
                    </div>

                    <div className="confirmation-mockup">
                        <img 
                            src="/images/aggressive-landing/mockup.png" 
                            alt="YT Copycat" 
                            className="confirmation-mockup-image" 
                        />
                    </div>
                </div>
            </section>

            {/* Instructions Section */}
            <section className="alp-section">
                <div className="alp-container">
                    <div className="confirmation-instructions">
                        <h2 className="confirmation-steps-title">
                            Next Steps <span className="alp-badge">IMPORTANT</span>
                        </h2>

                        <div className="confirmation-step">
                            <h3 className="confirmation-step-title">
                                1. Check your inbox
                            </h3>
                            <p className="confirmation-step-description">
                                We've just sent you an email containing your login credentials and detailed instructions to start using YT Copycat. If you can't find this email, please check your <strong>spam/junk folder</strong>.
                            </p>
                        </div>

                        <div className="confirmation-step">
                            <h3 className="confirmation-step-title">
                                2. Log in to your account
                            </h3>
                            <p className="confirmation-step-description">
                                Use the credentials provided in the email to log in to your YT Copycat account and start generating content immediately.
                            </p>
                            <div className="confirmation-button-container">
                                <a href="/login" className="alp-btn">
                                    Access my account
                                </a>
                            </div>
                        </div>

                        <div className="confirmation-step">
                            <h3 className="confirmation-step-title">
                                3. Need help?
                            </h3>
                            <p className="confirmation-step-description">
                                If you haven't received your access email or if you encounter any difficulties, don't hesitate to contact our support team at <strong>contact@ytcopycat.com</strong> and we'll help you resolve your issue quickly.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="alp-footer">
                <div className="alp-container">
                    <div className="alp-footer-links">
                        <a href="/terms">Terms of Service</a>
                        <a href="/privacy">Privacy Policy</a>
                        <a href="/refund">Refund Policy</a>
                        <a href="/contact">Contact Us</a>
                    </div>
                    <p className="alp-footer-text">
                        © {new Date().getFullYear()} YT Copycat. All Rights Reserved.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default AggressiveConfirmationPage; 