const OpenAIKeyHelp = () => {
    return (
        <div className="openai-key-help">
            <h3>How to Obtain Your OpenAI API Key</h3>
            
            <div className="steps">
                <div className="step">
                    <h4>1. Sign Up for an OpenAI Account</h4>
                    <p>Visit the <a href="https://platform.openai.com/signup" target="_blank" rel="noopener noreferrer">OpenAI sign-up page</a> and create an account using your email address. Follow the verification steps to activate your account.</p>
                </div>

                <div className="step">
                    <h4>2. Access the API Keys Section</h4>
                    <p>Once logged in, click on your profile icon in the top-right corner and select "View API keys" from the dropdown menu to navigate to the <a href="https://platform.openai.com/api-keys" target="_blank" rel="noopener noreferrer">API keys section</a>.</p>
                </div>

                <div className="step">
                    <h4>3. Create a New Secret Key</h4>
                    <p>Click on the "Create new secret key" button. Provide a descriptive name for your key (e.g., "MyProjectKey") to easily identify its purpose later.</p>
                </div>

                <div className="step">
                    <h4>4. Copy and Secure Your API Key</h4>
                    <p>After the key is generated, copy it immediately and store it in a secure location. For security reasons, it will not be displayed again.</p>
                </div>

                <div className="step">
                    <h4>5. Add a Payment Method and Fund Your Account</h4>
                    <p>To use the API, you must add a payment method. Go to the "Billing" section in your account settings and enter your payment details. You should fund your account with at least <strong>$10</strong> to get started, which should be enough for a while.</p>
                </div>

                <div className="step">
                    <h4>6. Add Your Key to YT Copycat</h4>
                    <p>Paste your API key in the "OpenAI API Key" field in your YT Copycat settings and click "Save API Key". Your key will be securely stored and used for all your future generations.</p>
                </div>
            </div>

            <div className="warning">
                <p><strong>Important:</strong> Keep your API key confidential and avoid sharing it publicly. If you suspect your key has been compromised, revoke it immediately and generate a new one through your OpenAI dashboard.</p>
            </div>
        </div>
    );
};

export default OpenAIKeyHelp;
