import React, { useState } from 'react';
import './AggressiveLandingPage.css';
import MarketingCarousel from './MarketingCarousel';
import LoadingButton from '../UI/LoadingButton';
import { useNavigate } from 'react-router-dom';

const AggressiveLandingPage = () => {
    const [openFaq, setOpenFaq] = useState(null);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const navigate = useNavigate();

    const toggleFaq = (index) => {
        if (openFaq === index) {
            setOpenFaq(null);
        } else {
            setOpenFaq(index);
        }
    };

    const handleCheckout = async () => {
        try {
            setCheckoutLoading(true);
            navigate('/live-checkout');
        } catch (error) {
            console.error('Erreur pendant la redirection:', error);
        } finally {
            setCheckoutLoading(false);
        }
    };

    const faqItems = [
        {
            question: "How exactly does this system work?",
            answer: "YT Copycat uses advanced AI technology to transform YouTube videos into written content. Simply paste any YouTube URL, and our intelligent system analyzes the video, extracts the most valuable information, and generates unique content in your preferred format (blog post, social media thread, newsletter, etc.) within seconds."
        },
        {
            question: "Is this method compliant with YouTube's terms of service?",
            answer: "Absolutely! Our system doesn't copy or republish video content directly but creates entirely new, original written content based on the concepts presented in the video. This falls within fair use guidelines as it transforms the content into a different medium while adding value through AI enhancement and reorganization."
        },
        {
            question: "Do I need any technical skills to make this work?",
            answer: "None whatsoever! If you can copy and paste a URL, you have all the technical skills required. Simply paste the YouTube URL, choose your desired output format, adjust any preferences if desired, and receive your professionally formatted content in seconds."
        },
        {
            question: "Do I need to provide my own OpenAI API key?",
            answer: "Yes, YT Copycat requires your own OpenAI API key. This approach helps us maintain affordable pricing while giving you complete control over your API usage. Don't worry – we provide comprehensive step-by-step instructions for setting up your API key quickly and easily within our members area."
        },
        {
            question: "How much will I spend on API credits?",
            answer: "Most users typically spend between $5-20 monthly for regular content creation. A standard blog post might cost approximately $0.10-0.30 in API credits. This pay-as-you-go approach ensures you only pay for your actual usage rather than expensive flat-rate subscriptions."
        },
        {
            question: "What languages does YT Copycat support?",
            answer: "YT Copycat supports more than 10 languages, including English, Spanish, French, German, Italian, Portuguese, Dutch, Russian, Japanese, and Chinese. This multilingual capability allows you to create content for diverse international audiences."
        },
        {
            question: "Can I customize the tone and style of the content?",
            answer: "Definitely! You can adjust multiple aspects including tone (professional, conversational, informative), writing style, content structure, level of detail, and voice to perfectly match your brand identity or specific platform requirements."
        },
        {
            question: "Is there a limit to how many videos I can process?",
            answer: "There are no artificial restrictions! You can process as many videos as you need. The only practical limitation is your OpenAI API usage, which you manage directly through your account."
        },
        {
            question: "Is the content SEO-friendly?",
            answer: "Yes, all generated content is designed with search engine optimization in mind. The system naturally incorporates relevant keywords and follows best practices for content structure, including appropriate headings and formatting to help your content perform well in search results."
        },
        {
            question: "Can I monetize the content created with YT Copycat?",
            answer: "Absolutely! The content is 100% yours to monetize through various channels such as advertising, affiliate marketing, promoting your products or services, building email subscriber lists, or offering content creation services to clients (covered under your commercial license)."
        },
        {
            question: "Is there a money-back guarantee?",
            answer: "Yes, we offer a comprehensive 30-day money-back guarantee. If you're not completely satisfied with YT Copycat for any reason, simply contact our support team within 30 days of purchase for a full refund with no questions asked."
        },
        {
            question: "How do I get started?",
            answer: "Simply click the 'Get Started Now' button on this page to create your account. You'll be guided through a quick and simple setup process and can begin generating professional content within minutes."
        }
    ];

    return (
        <div className="alp">
            {/* Hero Section */}
            <section className="alp-section">
                <div className="alp-container">
                    <h2>An <span className="alp-highlight">*EXCLUSIVE*</span> <span className="alp-gradient-text">YouTube</span> Strategy Allows You To</h2>
                    <h1>
                        Unlock <span className="alp-highlight-2">Unlimited Quality Traffic</span> <br /> On Demand <span className="alp-badge">In Under 60 Seconds...</span>
                    </h1>
                    <h2 className="alp-text-center">
                        Now You Can Apply These Proven Methods That Have Generated <span className="alp-underline">Significant Online Income!</span>
                    </h2>

                    <div className="alp-banner">
                        <p><strong>Zero</strong> Camera Experience Required | <strong>No</strong> Video Editing Skills Needed | <strong>Generate</strong> Free Traffic Instantly | <strong>Direct</strong> Visitors Anywhere</p>
                    </div>

                    <p className="alp-text-center" style={{ marginTop: '30px' }} >Transform <strong>ANY YouTube Video</strong> Into <strong>Multiple High-Converting Traffic Sources</strong> & <span className="alp-highlight-2">Generate Substantial Views And Followers</span> - For Any Market In Any Language Automatically <strong>Eliminates $1,000+ Annual Costs</strong> & Enables You To Create, Profit From & Offer Unlimited Traffic Resources With <strong>A Commercial License At No Extra Cost...</strong></p>
                </div>
            </section>

            <section className="alp-section alp-no-padding">
                <div className="alp-container alp-no-padding">
                    <div className="alp-grid">

                        <div className="alp-col">

                            <img src="/images/aggressive-landing/robot.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        </div>
                        <div className="alp-col">

                            <ul className="alp-list alp-list-check">
                                <li className="alp-list-item">Copy-Paste ANY YouTube URL or Prompt To Get Traffic</li>
                                <li className="alp-list-item">Generate Unlimited FREE Buyer Traffic</li>
                                <li className="alp-list-item">Get FREE Followers & Subscribers DAILY</li>
                                <li className="alp-list-item">Send Traffic TO ANY Offer, Page, Or Link</li>
                                <li className="alp-list-item">Works In Just 57 Seconds Flat</li>
                                <li className="alp-list-item">No Face Recording & No Video Skills Needed</li>
                                <li className="alp-list-item">Turn ANY YouTube URL Into Brand New Original Videos</li>
                                <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Blog Articles</li>
                                <li className="alp-list-item">Turn ANY YouTube URL Into Limitless X.com Posts</li>
                                <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Newsletters</li>
                                <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Transcripts</li>
                                <li className="alp-list-item">In-built Custom Prompt Automation Included</li>
                                <li className="alp-list-item">Works In Multiple Languages</li>
                                <li className="alp-list-item">Works In Multiple Niches</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="alp-section alp-section-accent">
                <div className="alp-container alp-text-center">
                    <h2>
                        Secure YT Copycat & Save $184.05 From Regular Price $199/Month
                    </h2>
                    <p>Special Offer: Just $14.95 One-Time Payment</p>
                    <div className="alp-mt-lg alp-mb-lg">
                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleCheckout}
                            className="alp-btn alp-btn-light"
                        >
                            Click Here To Get Started
                        </LoadingButton>
                        <div className="alp-mt-lg">
                            <img src="/images/aggressive-landing/payments.png" alt="Payment Methods" style={{ maxWidth: '500px' }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section alp-section-banner">
                <p>Limited-Time Launch Offer: $14.95 - Available For A Short Period</p>
            </section>

            {/* 3 STEPS AWAY FROM GENERATING ENDLESS FREE BUYER TRAFFIC */}
            <section className="alp-section">
                <div className="alp-container">
                    <h2>Follow Just 3 Simple Steps</h2>
                    <p>To Save Hundreds of Dollars While Creating Revenue Opportunities That Smart Content Creators, Marketers & Influencers Are Already Leveraging</p>
                    <div className="alp-grid">
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/step1.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">
                            <h3>
                                <span className="alp-badge">Step #1</span>
                            </h3>
                            <h2>
                                Access Your Account
                            </h2>
                            <p>
                                Enter the intuitive dashboard designed for simplicity and efficiency. Even if you're not tech-savvy, you'll feel right at home with our user-friendly interface that makes content creation effortless.
                            </p>
                        </div>
                    </div>

                    <div className="alp-grid">

                        <div className="alp-col">
                            <h3>
                                <span className="alp-badge">Step #2</span>
                            </h3>
                            <h2>
                                Insert Your URL
                            </h2>
                            <p>
                                Simply copy and paste ANY YouTube video link or enter your custom prompt. Our system immediately analyzes the content and prepares it for transformation into multiple formats, giving you instant access to a wealth of content possibilities.
                            </p>
                        </div>
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/step2.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                    </div>

                    <div className="alp-grid">
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/step3.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">
                            <h3>
                                <span className="alp-badge">Step #3</span>
                            </h3>
                            <h2>
                                Generate & Deploy
                            </h2>
                            <p>
                                With one click, transform the content into multiple traffic-generating assets. Save thousands on content creation costs while building valuable resources you can use for your own business or offer as professional services to clients. Each piece of content is uniquely crafted to maintain originality while capturing the essence of the source material.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section">
                <div className="alp-container">

                    <p className='alp-subtitle'>
                        Start Utilizing The Same Techniques That Successful Content Creators Are Using To Generate Consistent Traffic Without Appearing On Camera!
                    </p>
                    <h2>
                        Convert ANY YouTube Video Into <span className='alp-badge'>Multiple High-Quality</span> <br /><span className='alp-badge'>Traffic Resources</span> & Build Your Audience In Any Market And Language
                    </h2>

                    <div className="alp-grid-2">

                        <img src="/images/aggressive-landing/1.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        <img src="/images/aggressive-landing/2.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        <img src="/images/aggressive-landing/3.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        <img src="/images/aggressive-landing/4.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />



                    </div>
                </div>
            </section>

            {/* Marketing Costs Section */}
            <section className="alp-section">
                <div className="alp-container">
                    <h2>

                        Finally Eliminate Those Expensive <span className="alp-badge">Monthly Subscriptions</span> For Traffic, Lead Generation & Marketing Tools!
                    </h2>
                    <p className="alp-subtitle alp-text-center">
                        Our solution costs a fraction of alternatives while delivering superior results!
                    </p>

                    <MarketingCarousel />

                </div>
            </section>

            {/* CTA Section */}
            <section className="alp-section alp-section-accent">
                <div className="alp-container alp-text-center">
                    <h2>
                        Secure YT Copycat & Save $184.05 From Regular Price $199/Month
                    </h2>
                    <p>Special Offer: Just $14.95 One-Time Payment</p>
                    <div className="alp-mt-lg alp-mb-lg">
                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleCheckout}
                            className="alp-btn alp-btn-light"
                        >
                            Click Here To Get Started
                        </LoadingButton>
                        <div className="alp-mt-lg">
                            <img src="/images/aggressive-landing/payments.png" alt="Payment Methods" style={{ maxWidth: '500px' }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section alp-section-banner">
                <p>Limited-Time Launch Offer: $14.95 - Available For A Short Period</p>
            </section>

            <section className="alp-section">
                <div className="alp-container">


                    <h2>
                        <span className='alp-badge'>Discover</span> What Professional Content Creators Charge For Similar Services,
                    </h2>
                    <p className='alp-subtitle'>
                        And See What Early YT Copycat Users Are Saying About Their Results:
                    </p>

                    <div className="alp-grid-2">

                        <img src="/images/aggressive-landing/fvr1.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        <img src="/images/aggressive-landing/fvr2.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        <img src="/images/aggressive-landing/fvr3.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        <img src="/images/aggressive-landing/fvr4.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />



                    </div>
                </div>
            </section>

            <section className="alp-section alp-section-dark">
                <div className="alp-container">
                    <div className="alp-grid">
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/woman.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">
                            <h2>
                                Let's <span className="alp-badge">Be Straightforward...</span>
                            </h2>
                            <p>
                                Every Online Business Requires A Consistent Source Of Targeted Traffic To Succeed!
                            </p>
                            <p>
                                But traditional methods often come with Substantial Monthly Expenses... easily adding up to thousands of dollars annually.
                            </p>
                            <p>
                                We're committed to solving this challenge, which is why we developed this Complete Traffic Generation System - including a Commercial License that lets you use it for client work too!
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section">
                <div className="alp-container">
                    <h2>
                        YT Copycat Outperforms <span className="alp-badge">All Alternatives!</span>
                    </h2>

                    <div className="alp-grid">
                        <div className="alp-col">
                            <div className="alp-comparison alp-comparison-negative">
                                <h3>WITHOUT YT Copycat:</h3>
                                <ul className="alp-list alp-list-cross">
                                    <li className="alp-list-item">Invest Thousands In Content Creation Tools</li>
                                    <li className="alp-list-item">Spend Excessively On Content Production</li>
                                    <li className="alp-list-item">Struggle With Content Creation Blocks</li>
                                    <li className="alp-list-item">Insufficient Engagement To Justify Costs</li>
                                    <li className="alp-list-item">Confusion & Lack Of Content Direction</li>
                                </ul>
                            </div>
                        </div>

                        <div className="alp-col">
                            <div className="alp-comparison alp-comparison-positive">
                                <h3>WITH YT Copycat:</h3>
                                <ul className="alp-list alp-list-check">
                                    <li className="alp-list-item">Reduce Content Creation Expenses Dramatically</li>
                                    <li className="alp-list-item">Produce Content 10X More Efficiently</li>
                                    <li className="alp-list-item">Achieve Higher Engagement & Conversions</li>
                                    <li className="alp-list-item">Create Abundant Content From Each Video</li>
                                    <li className="alp-list-item">Implement A Clear Content Strategy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Two Column Dark Section */}
            <section className="alp-section alp-section-dark">
                <div className="alp-container">
                    <div className="alp-grid">

                        <div className="alp-col">
                            <p>
                                Unfortunately...
                            </p>
                            <h2>
                                These Are Common Challenges Most Online Marketers Face
                            </h2>
                            <p>
                                When Attempting To Generate Quality Traffic & Revenue...
                            </p>
                            <ul className="alp-list alp-list-cross">
                                <li className="alp-list-item">High Costs For Content Creation Tools</li>
                                <li className="alp-list-item">Time-Consuming Content Production</li>
                                <li className="alp-list-item">Low Engagement Despite High Effort</li>
                                <li className="alp-list-item">Limited Content From Each Source</li>
                                <li className="alp-list-item">Unclear Direction For Content Strategy</li>
                            </ul>
                            <p>
                                ...And That Doesn't Even Account For The Ongoing Monthly Expenses That Impact Your Bottom Line!
                            </p>
                        </div>
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/depressed.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section">
                <div className="alp-container">
                    <div className="alp-grid">

                        <div className="alp-col">
                            <img src="/images/aggressive-landing/broke.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">

                            <h2>
                                These Problems Directly <br /><span className='alp-badge'>Impact Your Results...</span>
                            </h2>
                            <ul className="alp-list alp-list-cross">
                                <li className="alp-list-item">Reduced Audience Engagement</li>
                                <li className="alp-list-item">Decreased Conversion Rates</li>
                                <li className="alp-list-item">Diminished Revenue</li>
                                <li className="alp-list-item">Poor Customer Retention</li>
                                <li className="alp-list-item">Negative Brand Perception</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>

            <section className="alp-section">
                <div className="alp-container">

                    <h2>
                        That's Why We Developed This <span className='alp-badge'>Complete</span> Solution You Can Rely On,
                    </h2>

                    <p className='alp-subtitle'>That Reduces Your Expenses While Creating New Income Streams - Simultaneously!</p>

                    <div className="alp-grid">

                        <div className="alp-col">

                            <p>As solution developers, we carefully analyzed the biggest challenges online marketers face and addressed them all in one comprehensive system!</p>
                            <p>YT Copycat helps you eliminate monthly expenses for Traffic, Lead Generation & Marketing Tools...</p>
                            <p>...and that's just the beginning!</p>
                            <p>YT Copycat also enables you to Create, Monetize & Offer Professional Traffic Generation & Digital Marketing Services to clients - either as one-time projects or recurring monthly services...</p>
                            <p>This Completely Transforms The Equation!</p>
                            <p>Whether you're new to digital marketing or an experienced professional - YT Copycat adapts to your needs.</p>

                        </div>

                        <div className="alp-col">
                            <img src="/images/aggressive-landing/success_man.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>

                    </div>
                </div>
            </section >

            {/* Hero Section */}
            <section className="alp-section">
                <div className="alp-container">
                    <p className='alp-subtitle' style={{ letterSpacing: '10px' }}>INTRODUCING :</p>

                    <h1 style={{ fontSize: '70px', marginBottom: '60px' }}>YT Copycat</h1>

                    <h2>An <span className="alp-highlight">*EXCLUSIVE*</span> <span className="alp-gradient-text">YouTube</span> Strategy Allows You To</h2>
                    <h1>
                        Unlock <span className="alp-highlight-2">Unlimited Quality Traffic</span> <br /> On Demand <span className="alp-badge">In Under 60 Seconds...</span>
                    </h1>
                    <h2 className="alp-text-center">
                        Now You Can Apply These Proven Methods That Have Generated <span className="alp-underline">Significant Online Income!</span>
                    </h2>

                    <div className="alp-banner">
                        <p><strong>Zero</strong> Camera Experience Required | <strong>No</strong> Video Editing Skills Needed | <strong>Generate</strong> Free Traffic Instantly | <strong>Direct</strong> Visitors Anywhere</p>
                    </div>

                    <p className="alp-text-center" style={{ marginTop: '30px' }} >Transform <strong>ANY YouTube Video</strong> Into <strong>Multiple High-Converting Traffic Sources</strong> & <span className="alp-highlight-2">Generate Substantial Views And Followers</span> - For Any Market In Any Language Automatically <strong>Eliminates $1,000+ Annual Costs</strong> & Enables You To Create, Profit From & Offer Unlimited Traffic Resources With <strong>A Commercial License At No Extra Cost...</strong></p>
                </div>

                <img src="/images/aggressive-landing/mockup.png" alt="Mockup" className="alp-img-fluid alp-centered-img" style={{ width: '1000px', margin: '0 auto' }} />
            </section>

            {/* CTA Section */}
            <section className="alp-section alp-section-accent">
                <div className="alp-container alp-text-center">

                    <h2>
                        Secure YT Copycat & Save $184.05 From Regular Price $199/Month
                    </h2>
                    <p>Special Offer: Just $14.95 One-Time Payment</p>
                    <div className="alp-mt-lg alp-mb-lg">
                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleCheckout}
                            className="alp-btn alp-btn-light"
                        >
                            Click Here To Get Started
                        </LoadingButton>
                        <div className="alp-mt-lg">
                            <img src="/images/aggressive-landing/payments.png" alt="Payment Methods" style={{ maxWidth: '500px' }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section alp-section-banner">
                <p>Limited-Time Launch Offer: $14.95 - Available For A Short Period</p>
            </section>

            {/* 3 STEPS AWAY FROM GENERATING ENDLESS FREE BUYER TRAFFIC */}
            <section className="alp-section">
                <div className="alp-container">
                    <h2>Follow Just 3 Simple Steps</h2>
                    <p className='alp-subtitle'>To Save Hundreds of Dollars While Creating Revenue Opportunities That Smart Content Creators, Marketers & Influencers Are Already Leveraging</p>
                    <div className="alp-grid">
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/step1.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">
                            <h3>
                                <span className="alp-badge">Step #1</span>
                            </h3>
                            <h2>
                                Access Your Account
                            </h2>
                            <p>
                                Enter the intuitive dashboard designed for simplicity and efficiency. Even if you're not tech-savvy, you'll feel right at home with our user-friendly interface that makes content creation effortless.
                            </p>
                        </div>
                    </div>

                    <div className="alp-grid">

                        <div className="alp-col">
                            <h3>
                                <span className="alp-badge">Step #2</span>
                            </h3>
                            <h2>
                                Insert Your URL
                            </h2>
                            <p>
                                Simply copy and paste ANY YouTube video link or enter your custom prompt. Our system immediately analyzes the content and prepares it for transformation into multiple formats, giving you instant access to a wealth of content possibilities.
                            </p>
                        </div>
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/step2.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                    </div>

                    <div className="alp-grid">
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/step3.png" alt="Testimonial" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">
                            <h3>
                                <span className="alp-badge">Step #3</span>
                            </h3>
                            <h2>
                                Generate & Deploy
                            </h2>
                            <p>
                                With one click, transform the content into multiple traffic-generating assets. Save thousands on content creation costs while building valuable resources you can use for your own business or offer as professional services to clients. Each piece of content is uniquely crafted to maintain originality while capturing the essence of the source material.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section">
                <div className="alp-container">
                    <h2>
                        YT Copycat Benefits <span className="alp-badge">Every Digital Professional</span>
                    </h2>

                    <div className="alp-cards-grid">
                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/affiliate-marketers.png" alt="Affiliate Marketers" />
                            <div className="alp-card-title">Affiliate Marketers</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/website-owners.png" alt="Website Owners" />
                            <div className="alp-card-title">Website Owners</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/ecommerce-store-owners.png" alt="eCommerce Store Owners" />
                            <div className="alp-card-title">eCommerce Store Owners</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/bloggers.png" alt="Bloggers" />
                            <div className="alp-card-title">Bloggers</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/freelancers.png" alt="Freelancers" />
                            <div className="alp-card-title">Freelancers</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/agencies.png" alt="Agencies" />
                            <div className="alp-card-title">Agencies</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/influencers.png" alt="Influencers" />
                            <div className="alp-card-title">Influencers</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/small-business-owners.png" alt="Small Business Owners" />
                            <div className="alp-card-title">Small Business Owners</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/content-creators.png" alt="Content Creators" />
                            <div className="alp-card-title">Content Creators</div>
                        </div>

                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/translators.png" alt="Translators" />
                            <div className="alp-card-title">Translators</div>
                        </div>



                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/podcasters.png" alt="Podcasters" />
                            <div className="alp-card-title">Podcasters</div>
                        </div>


                        <div className="alp-card-item">
                            <img src="/images/aggressive-landing/authors.png" alt="Authors" />
                            <div className="alp-card-title">Authors</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section alp-section-dark">
                <div className="alp-container">
                    <div className="alp-grid">


                        <div className="alp-col">

                            <h2>
                                Perfect For Beginners & Experienced Content Creators
                            </h2>
                            <p>Whether you're just starting your digital journey or you've been in the industry for years...</p>
                            <p>YT Copycat streamlines your workflow, provides multiple monetization options, and eliminates expensive monthly subscriptions from other services!</p>
                            <p>And The Most Exciting Part?</p>
                            <p>During our initial launch period, you can secure lifetime access for just $14.95 - One Payment, Lifetime Usage!</p>
                            <p>That's Correct! A Complete Content Creation System For Less Than A Casual Dinner!</p>

                        </div>
                        <div className="alp-col">

                            <img src="/images/aggressive-landing/happy_man.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        </div>

                    </div>
                </div>
            </section>

            <section className="alp-section">
                <div className="alp-container">
                    <div className="alp-grid">
                        <div className="alp-col">

                            <img src="/images/aggressive-landing/interro.png" alt="Benefits" className="alp-img-fluid alp-mt-lg" />
                        </div>
                        <div className="alp-col">

                            <h2>I'm Interested - What's The Investment For All This?</h2>

                            <p>Before answering, consider what you're receiving.</p>
                            <p>Inside the Member's Area, you'll find a Completely Ready-To-Use System for creating, utilizing and offering services that EVERYONE in today's digital landscape needs, comprehensive video tutorials on implementation, and the potential to generate substantial revenue...</p>
                            <p>Ask yourself this:</p>

                        </div>



                    </div>
                </div>
            </section>

            {/* First added section */}
            <section className="alp-section">
                <div className="alp-container">
                    <div className="alp-grid">
                        <div className="alp-col">
                            <h2>Wouldn't You <span className="alp-highlight">Like To:</span></h2>
                            <ul className="alp-list alp-list-check">
                                <li className="alp-list-item">Generate online revenue without requiring extensive experience or technical expertise</li>
                                <li className="alp-list-item">Eliminate ongoing monthly expenses and redirect those funds to other priorities</li>
                                <li className="alp-list-item">Help businesses attract new customers and earn their appreciation</li>
                                <li className="alp-list-item">Create options beyond traditional employment that don't depend on fixed paychecks</li>
                                <li className="alp-list-item">Build credibility and recognition among your peers and network</li>
                                <li className="alp-list-item">Enjoy greater flexibility to pursue personal interests - travel, purchases, or experiences</li>
                                <li className="alp-list-item">Dedicate your time to activities you enjoy rather than obligations</li>
                            </ul>
                        </div>
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/student.png" alt="Freedom Lifestyle" className="alp-img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Second added section */}
            <section className="alp-section">
                <div className="alp-container">
                    <div className="alp-grid">
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/colorfun_man.png" alt="Affordable Price" className="alp-img-fluid" />
                        </div>
                        <div className="alp-col">
                            <h2>What Would This Be <span className="alp-badge">Worth To You?</span></h2>
                            <p>Several hundred dollars?</p>
                            <p>Perhaps even a few thousand?</p>
                            <p>Here's the <span className="alp-highlight">exciting news</span> - your investment is nowhere near those figures!</p>
                        </div>
                    </div>
                </div>
            </section>


            {/* Final mockup Section */}
            <section className="alp-section">
                <div className="alp-container">

                    <div className="alp-final-offer-mockup">
                        <h2>
                            For A Limited Time Only: Special Launch Price Of $14.95
                        </h2>
                        <img src="/images/aggressive-landing/mockup.png" alt="Final Offer Mockup" className="alp-img-fluid alp-centered-img" />
                    </div>

                    <div className="alp-final-offer-list">
                        <ul className="alp-list alp-list-check">
                            <li className="alp-list-item">Copy-Paste ANY YouTube URL or Prompt To Get Traffic</li>
                            <li className="alp-list-item">Generate Unlimited FREE Buyer Traffic</li>
                            <li className="alp-list-item">Get FREE Followers & Subscribers DAILY</li>
                            <li className="alp-list-item">Send Traffic TO ANY Offer, Page, Or Link</li>
                            <li className="alp-list-item">Works In Just 57 Seconds Flat</li>
                            <li className="alp-list-item">No Face Recording & No Video Skills Needed</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Brand New Original Videos</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Blog Articles</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless X.com Posts</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Newsletters</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Transcripts</li>
                            <li className="alp-list-item">In-built Custom Prompt Automation Included</li>
                            <li className="alp-list-item">Works In Multiple Languages</li>
                            <li className="alp-list-item">Works In Multiple Niches</li>
                        </ul>
                    </div>

                    <div className="alp-final-offer-price">
                        <h2>TOTAL VALUE:</h2>
                        <h1 className='alp-final-offer-old-price'>$297</h1>
                        <p>Today's Special Offer:</p>
                        <h1>$14.95</h1>
                        <p>Regular Value: $297</p>
                        <p>Limited Time: $14.95</p>
                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleCheckout}
                            className="alp-btn alp-btn-light"
                        >
                            BUY NOW!
                        </LoadingButton>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="alp-section alp-section-accent">
                <div className="alp-container alp-text-center">

                    <h2>
                        Secure YT Copycat & Save $184.05 From Regular Price $199/Month
                    </h2>
                    <p>Special Offer: Just $14.95 One-Time Payment</p>
                    <div className="alp-mt-lg alp-mb-lg">
                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleCheckout}
                            className="alp-btn alp-btn-light"
                        >
                            Click Here To Get Started
                        </LoadingButton>
                        <div className="alp-mt-lg">
                            <img src="/images/aggressive-landing/payments.png" alt="Payment Methods" style={{ maxWidth: '500px' }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="alp-section alp-section-banner">
                <p>Limited-Time Launch Offer: $14.95 - Available For A Short Period</p>
            </section>

            {/* First new discount section */}
            <section className="alp-section alp-section-dark">
                <div className="alp-container">
                    <h2>All This Value For Such An <span className="alp-highlight">Affordable</span> One-Time Price? <span className="alp-badge">Why?</span></h2>
                    <div className="alp-grid">
                        <div className="alp-col">

                            <p>This Exceptional Pricing Is Only Available During Our Launch Period!</p>
                            <p>After this initial offer expires, we may increase the price or convert to a subscription model. This one-time payment option likely won't be offered again.</p>
                            <p>If you want to take advantage of this opportunity - secure your copy NOW with <span className="alp-highlight">One Payment For Lifetime Access!</span></p>
                            <p>You have nothing to risk and everything to gain. And you're receiving a substantial discount - but ONLY for a limited time! The price will increase periodically according to our launch schedule, ultimately reaching $199 per month once the launch period ends...</p>
                            <p>It's a straightforward decision - for less than the cost of a casual meal, you can eliminate expensive subscription costs and start offering services that could generate significant revenue...</p>
                        </div>
                        <div className="alp-col">
                            <img src="/images/aggressive-landing/idea_woman.png" alt="Limited Time Offer" className="alp-img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            {/* FAQ Section */}
            <section className="alp-section">
                <div className="alp-container">
                    <h2 >
                        Frequently Asked <span className="alp-badge">Questions</span>
                    </h2>
                    <div className="alp-mt-lg">
                        {faqItems.map((faq, index) => (
                            <div
                                key={index}
                                className={`alp-accordion-item ${openFaq === index ? 'active' : ''}`}
                                onClick={() => toggleFaq(index)}
                            >
                                <div className="alp-accordion-header">
                                    <h3>{faq.question}</h3>
                                    <span>{openFaq === index ? '−' : '+'}</span>
                                </div>
                                <div className="alp-accordion-content">
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="alp-text-center alp-mt-lg">
                        <LoadingButton
                            isLoading={checkoutLoading}
                            onClick={handleCheckout}
                            className="alp-btn alp-btn-dark"
                        >
                            Click Here To Get Started
                        </LoadingButton>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="alp-footer">
                <div className="alp-container">
                    <div className="alp-footer-logo">
                        <img src="/images/aggressive-landing/logo.png" alt="Logo" className="alp-img-fluid" style={{ maxWidth: '150px' }} />
                    </div>
                    <div className="alp-footer-links">
                        <a href="/legal/terms">Terms of Service</a>
                        <a href="/legal/privacy">Privacy Policy</a>
                        <a href="/legal/refund">Refund Policy</a>
                        <a href="/legal/contact">Contact Us</a>
                    </div>
                    <p className="alp-footer-text alp-small-text">
                        © {new Date().getFullYear()} YT Copycat. All Rights Reserved.
                    </p>
                    <p className="alp-footer-text alp-small-text">
                        DISCLAIMER: Results may vary based on individual effort and implementation. This is not presented as a "get rich quick" solution.
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default AggressiveLandingPage; 