import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { countries } from '../../utils/countries'; // We'll create this
import '../Payment/CustomPaymentPopup.css';
import './AggressiveLandingPage.css';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AggressiveCheckoutPage = () => {
    const [paymentData, setPaymentData] = useState(null);
    const [isLoadingIntent, setIsLoadingIntent] = useState(true);
    const [loadError, setLoadError] = useState(null);
    
    useEffect(() => {
        const fetchPaymentIntent = async () => {
            try {
                setIsLoadingIntent(true);
                const response = await api.post("/create_onetime_payment", {
                    plan: {
                        price_id: "thepriceid",
                    }
                });
                
                setPaymentData({
                    clientSecret: response.data.clientSecret,
                    paymentIntentId: response.data.paymentIntentId
                });
                setIsLoadingIntent(false);
            } catch (error) {
                console.error('Erreur pendant le checkout:', error);
                setLoadError('Erreur lors de la préparation du paiement. Veuillez réessayer.');
                setIsLoadingIntent(false);
            }
        };
        
        fetchPaymentIntent();
    }, []);
    
    if (isLoadingIntent) {
        return (
            <div className="aggressive-checkout-loading">
                <div className="aggressive-checkout-loading-content">
                    <h2>Preparing your order...</h2>
                    <div className="aggressive-loading-spinner"></div>
                </div>
            </div>
        );
    }
    
    if (loadError) {
        return (
            <div className="aggressive-checkout-error">
                <div className="aggressive-checkout-error-content">
                    <h2>An error occurred</h2>
                    <p>{loadError}</p>
                    <button 
                        onClick={() => window.location.href = '/live'}
                        className="aggressive-back-button"
                    >
                        Return to the homepage
                    </button>
                </div>
            </div>
        );
    }
    
    return (
        <div className="aggressive-checkout-page">
            {paymentData && (
                <Elements
                    stripe={stripePromise}
                    options={{ clientSecret: paymentData.clientSecret }}
                >
                    <CheckoutForm 
                        clientSecret={paymentData.clientSecret}
                        paymentIntentId={paymentData.paymentIntentId}
                    />
                </Elements>
            )}
        </div>
    );
};

const CheckoutForm = ({ clientSecret, paymentIntentId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        if (!stripe || !elements) return;

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setError(submitError.message);
            setIsLoading(false);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        name: `${formData.firstName} ${formData.lastName}`,
                        email: formData.email,
                        address: {
                            line1: formData.addressLine1,
                            line2: formData.addressLine2 || undefined,
                            city: formData.city,
                            state: formData.state,
                            postal_code: formData.postalCode,
                            country: formData.country
                        }
                    }
                },
                return_url: `${window.location.origin}/ltd-purchase-confirmation`,
            }
        });

        if (error) {
            setError(error.message);
        }
        
        setIsLoading(false);
    };
    
    const handleCancel = () => {
        navigate('/live');
    };

    const paymentElementOptions = {
        layout: {
            type: 'tabs',
            defaultCollapsed: false
        },
        paymentMethodOrder: ['card', 'paypal'],
        defaultValues: {
            billingDetails: {
                name: `${formData.firstName} ${formData.lastName}`,
                email: formData.email,
                address: {
                    line1: formData.addressLine1,
                    line2: formData.addressLine2 || undefined,
                    city: formData.city,
                    state: formData.state,
                    postal_code: formData.postalCode,
                    country: formData.country
                }
            }
        }
    };

    // Fonction de debug pour pré-remplir le formulaire
    const fillDebugData = () => {
        setFormData({
            firstName: 'Fabien',
            lastName: 'Boco',
            email: 'fabien.boco@gmail.com',
            addressLine1: '17 Allée du mont vallier',
            addressLine2: '',
            city: 'Colomiers',
            state: '',
            postalCode: '31700',
            country: 'FR'
        });
    };

    // Ajouter un useEffect pour remplir automatiquement en développement
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            fillDebugData();
        }
    }, []);

    return (
        <div className="aggressive-checkout-container">
            <div className="aggressive-payment-columns">
                {/* Left Column - Summary */}
                <div className="aggressive-summary-column">
                    <button 
                        onClick={handleCancel} 
                        className="aggressive-back-button"
                    >
                        ← Retour à la page d'accueil
                    </button>
                    <div className="aggressive-subscription-summary">
                        <h2>YT Copycat <span style={{ color: '#ff6b00' }}>ONE-TIME</span> Offer</h2>
                        <div className="aggressive-plan-details">
                            <h3>Special Launch Period Price</h3>
                            <div className="aggressive-price-container">
                                <span className="aggressive-old-price">$297</span>
                                <p className="aggressive-current-price">$14.95</p>
                            </div>
                            <span className="aggressive-discount-badge">SAVE $282.05</span>
                            
                            <div className="aggressive-special-offer">
                                <p>Special Launch Period Only - Limited Time Offer!</p>
                            </div>
                        </div>
                        
                        <div className="aggressive-product-image">
                            <img src="/images/aggressive-landing/mockup.png" alt="YT Copycat Mockup" />
                        </div>

                        <div className="aggressive-payment-methods">
                            <img src="/images/aggressive-landing/payments.png" alt="Payment Methods" />
                        </div>
                    </div>

                    <div className="aggressive-benefits-section">
                        <h3>What You're Getting Today:</h3>
                        <ul className="aggressive-benefits-list alp-list alp-list-check">
                            <li className="alp-list-item">Copy-Paste ANY YouTube URL or Prompt To Get Traffic</li>
                            <li className="alp-list-item">Generate Unlimited FREE Buyer Traffic</li>
                            <li className="alp-list-item">Get FREE Followers & Subscribers DAILY</li>
                            <li className="alp-list-item">Send Traffic TO ANY Offer, Page, Or Link</li>
                            <li className="alp-list-item">Works In Just 57 Seconds Flat</li>
                            <li className="alp-list-item">No Face Recording & No Video Skills Needed</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Brand New Original Videos</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Blog Articles</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless X.com Posts</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Newsletters</li>
                            <li className="alp-list-item">Turn ANY YouTube URL Into Limitless Transcripts</li>
                            <li className="alp-list-item">In-built Custom Prompt Automation Included</li>
                            <li className="alp-list-item">Works In Multiple Languages</li>
                            <li className="alp-list-item">Works In Multiple Niches</li>
                        </ul>
                    </div>

                    <div className="aggressive-social-proof">
                        <div className="aggressive-testimonial">
                            "Game-changing tool! Tripled my content output while maintaining quality. The AI understands context perfectly."
                            <span className="aggressive-testimonial-author">- Thomas K., Blogger</span>
                        </div>
                        
                        <div className="aggressive-users-count">
                            <span className="count">5000+</span>
                            <span>users trust us worldwide</span>
                        </div>
                        
                        <div className="aggressive-trust-badges">
                            <span className="aggressive-trust-badge">
                                <span>🔒</span> 100% Secure payment
                            </span>
                            <span className="aggressive-trust-badge">
                                <span>⚡</span> Instant access after purchase
                            </span>
                            <span className="aggressive-trust-badge">
                                <span>↩️</span> 30-day money back guarantee
                            </span>
                        </div>
                    </div>
                </div>

                {/* Right Column - Payment Form */}
                <div className="aggressive-form-column">
                    <h2>Payment Details</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="aggressive-form-section">
                            <div className="aggressive-form-row">
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    required
                                    className="aggressive-form-input"
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    required
                                    className="aggressive-form-input"
                                />
                            </div>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                className="aggressive-form-input"
                            />
                            <input
                                type="text"
                                name="addressLine1"
                                placeholder="Address Line 1"
                                value={formData.addressLine1}
                                onChange={handleInputChange}
                                required
                                className="aggressive-form-input"
                            />
                            <input
                                type="text"
                                name="addressLine2"
                                placeholder="Address Line 2 (Optional)"
                                value={formData.addressLine2}
                                onChange={handleInputChange}
                                className="aggressive-form-input"
                            />
                            <div className="aggressive-form-row">
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    required
                                    className="aggressive-form-input"
                                />
                                <input
                                    type="text"
                                    name="state"
                                    placeholder="State/Province/Region"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    className="aggressive-form-input"
                                />
                            </div>
                            <div className="aggressive-form-row">
                                <input
                                    type="text"
                                    name="postalCode"
                                    placeholder="Postal Code"
                                    value={formData.postalCode}
                                    onChange={handleInputChange}
                                    required
                                    className="aggressive-form-input"
                                />
                                <select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    required
                                    className="aggressive-form-select"
                                >
                                    <option value="">Select Country</option>
                                    {countries.map(country => (
                                        <option key={country.code} value={country.code}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <PaymentElement options={paymentElementOptions} />

                        {error && <div className="aggressive-error-message">{error}</div>}

                        <div className="aggressive-checkout-summary">
                            <div className="aggressive-checkout-title">Order Summary</div>
                            <div className="aggressive-checkout-item">
                                <div className="aggressive-checkout-item-name">YouTube CopyCat - One-Time Purchase</div>
                                <div className="aggressive-checkout-item-price">$14.95</div>
                            </div>
                            <div className="aggressive-checkout-total">
                                <div>Total</div>
                                <div>$14.95</div>
                            </div>
                        </div>

                        <button 
                            type="submit" 
                            disabled={isLoading || !stripe} 
                            className="aggressive-submit-button"
                        >
                            {isLoading ? 'Processing...' : 'Pay Now'}
                        </button>
                        
                        <p className="aggressive-terms-text alp-small-text">
                            By clicking the button above, you agree to our <a href="/legal/terms">Terms of Service</a> and <a href="/legal/privacy">Privacy Policy</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AggressiveCheckoutPage; 