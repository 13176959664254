import React from 'react';
import LoadingButton from '../UI/LoadingButton';
import Toggle from '../UI/Toggle';

const PricingPlans = ({ 
    isYearly, 
    setIsYearly, 
    selectedPlan, 
    onPlanSelect, 
    onSubmit, 
    checkoutLoading, 
    user,
    className = '',
    prices,
    formatPrice
}) => {
    // Créer les plans en utilisant les données dynamiques
    const getPlans = () => {
        if (!prices) return [];

        const subscriptionPlan = {
            id: isYearly ? prices.yearly?.id : prices.monthly?.id,
            title: isYearly ? 'Yearly Plan' : 'Monthly Plan',
            price: isYearly 
                ? formatPrice(prices.yearly?.unit_amount / 100) 
                : formatPrice(prices.monthly?.unit_amount / 100),
            period: isYearly ? '/ year' : '/ month',
            monthlyEquivalent: isYearly 
                ? `= ${formatPrice((prices.yearly?.unit_amount / 100) / 12)}/month, billed annually` 
                : null,
            features: [
                'The latest OpenAI model for better content',
                'Up to 100 000 word of content generation per month',
                'YouTube to Blog article',
                'YouTube to Newsletter',
                'YouTube to Tweets/X',
                'YouTube to Linkedin',
                'YouTube video summarize tool',
                'YouTube to Custom prompt tool',
                '30+ Languages'
            ]
        };

        /* Commenté - LTD sera géré plus tard
        const lifetimePlan = {
            id: prices.lifetime?.id || process.env.REACT_APP_LTD_PRICE_ID,
            title: 'Lifetime Deal',
            subtitle: 'Bring your own key',
            price: formatPrice(prices.lifetime?.unit_amount / 100) || '$297',
            period: 'one-time',
            features: [
                'Everything in Monthly Plan',
                'Lifetime access',
                'No monthly word limit',
                'Use your own OpenAI API key',
                'Priority support',
                "30 days money back guarantee"
            ],
            highlight: true
        };
        */

        // return [subscriptionPlan, lifetimePlan];
        return [subscriptionPlan];
    };

    const plans = prices ? getPlans() : [
        {
            id: isYearly ? process.env.REACT_APP_YEAR_PRICE_ID : process.env.REACT_APP_MONTH_PRICE_ID,
            title: isYearly ? 'Yearly Plan' : 'Monthly Plan',
            price: isYearly ? '$180' : '$19',
            period: isYearly ? '/ year' : '/ month',
            monthlyEquivalent: isYearly ? '= $15/month, billed annually' : null,
            features: [
                'The latest OpenAI model for better content',
                'Up to 100 000 word of content generation per month',
                'YouTube to Blog article',
                'YouTube to Newsletter',
                'YouTube to Tweets/X',
                'YouTube to Linkedin',
                'YouTube video summarize tool',
                'YouTube to Custom prompt tool',
                '30+ Languages'
            ]
        }
        /* Commenté - LTD sera géré plus tard
        ,{
            id: process.env.REACT_APP_LTD_PRICE_ID,
            title: 'Lifetime Deal',
            subtitle: 'Bring your own key',
            price: '$297',
            period: 'one-time',
            features: [
                'Everything in Monthly Plan',
                'Lifetime access',
                'No monthly word limit',
                'Use your own OpenAI API key',
                'Priority support',
                "30 days money back guarantee"
            ],
            highlight: true
        }
        */
    ];

    const renderButton = (plan) => {
        // Garder la logique mais elle ne s'appliquera pas car nous n'avons plus de plan LTD
        const isLTD = plan.id === process.env.REACT_APP_LTD_PRICE_ID || plan.id === prices?.lifetime?.id;
        const isMonthlyOrYearly = 
            plan.id === process.env.REACT_APP_MONTH_PRICE_ID || 
            plan.id === process.env.REACT_APP_YEAR_PRICE_ID ||
            plan.id === prices?.monthly?.id ||
            plan.id === prices?.yearly?.id;
        
        // const showTrialButton = user && !user.consumed_trial && isMonthlyOrYearly;

        const showTrialButton = false;


        return (
            <LoadingButton
                isLoading={checkoutLoading && selectedPlan === plan.id}
                onClick={() => onSubmit(plan.id)}
                className={`button-plain ${plan.highlight ? 'button-highlight' : ''}`}
            >
                {isLTD ? 'Get Lifetime Access' : (showTrialButton ? 'Start 7 days free trial' : 'Get Started')}
            </LoadingButton>
        );
    };

    return (
        <div className={className}>
            <Toggle
                checked={isYearly}
                onChange={() => setIsYearly(!isYearly)}
                label={isYearly ? "Yearly billing" : "Monthly billing"}
            />
            <div className="plans-grid">
                {plans.map((plan) => (
                    <div 
                        key={plan.id} 
                        className={`plan-card ${plan.highlight ? 'plan-highlight' : ''} ${selectedPlan === plan.id ? 'selected' : ''}`}
                        onClick={() => onPlanSelect(plan.id)}
                    >
                        <div className="pricing-header">
                            <h3>{plan.title}</h3>
                            {plan.subtitle && <p className="subtitle">{plan.subtitle}</p>}
                        </div>
                        <div className="plan-price">
                            <span className="amount">{plan.price}</span>
                            <span className="period">{plan.period}</span>
                        </div>
                        {plan.monthlyEquivalent && (
                            <div className="monthly-equivalent">{plan.monthlyEquivalent}</div>
                        )}
                        <div className="plan-features">
                            {plan.features.map((feature, index) => (
                                <div key={index} className="feature">
                                    <i className="fas fa-check"></i>
                                    <span>{feature}</span>
                                </div>
                            ))}
                        </div>
                        {renderButton(plan)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PricingPlans; 