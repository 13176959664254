import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { countries } from '../../utils/countries'; // We'll create this
import './CustomPaymentPopup.css';
import Popup from '../UI/Popup';
import { usePricing } from './PricingContext';
import api from '../api';

const CustomPaymentPopup = ({ selectedPlan, onClose, clientSecret, subscriptionId, baseMonthlyPrice }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isCompany, setIsCompany] = useState(false);
    const { currency } = usePricing();
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        companyName: '',
        vatNumber: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        if (!stripe || !elements) return;

        // Mettre à jour les métadonnées de l'abonnement
        try {
            const baseMetadata = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                city: formData.city,
                state: formData.state,
                postalCode: formData.postalCode,
                country: formData.country,
                customerType: isCompany ? 'business' : 'individual'
            };

            // Ajouter les informations d'entreprise si nécessaire
            const metadata = isCompany ? {
                ...baseMetadata,
                companyName: formData.companyName,
                vatNumber: formData.vatNumber
            } : baseMetadata;

            
            await api.post(`/update-subscription/${subscriptionId}`, { metadata });
        } catch (error) {
            console.error('Error updating subscription metadata:', error);
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setError(submitError.message);
            setIsLoading(false);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        name: `${formData.firstName} ${formData.lastName}`,
                        email: formData.email,
                        address: {
                            line1: formData.addressLine1,
                            line2: formData.addressLine2 || undefined,
                            city: formData.city,
                            state: formData.state,
                            postal_code: formData.postalCode,
                            country: formData.country
                        }
                    }
                },
                return_url: `${window.location.origin}/purchase_confirmation`,
            }
        });

        if (error) {
            setError(error.message);
        }
        
        setIsLoading(false);
    };

    const formatPrice = (amount) => {
        return new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currency || 'EUR'
        }).format(amount/100);
    };

    const formatPlanDetails = () => {
        if (!selectedPlan) return { displayName: '', displayPrice: '', period: '' };

        let displayName, displayPrice, period, discount;
        const basePrice = formatPrice(baseMonthlyPrice);
        
        if (selectedPlan.id === 'yearly') {
            displayName = 'Annual Subscription';
            period = 'year';
            // Calcul de la réduction: ((prix mensuel * 12) - prix annuel) / (prix mensuel * 12) * 100
            discount = Math.round(((baseMonthlyPrice * 12) - selectedPlan.unit_amount) / (baseMonthlyPrice * 12) * 100);
        } else if (selectedPlan.id === 'quarterly') {
            displayName = 'Quarterly Subscription';
            period = 'quarter';
            // Calcul de la réduction: ((prix mensuel * 3) - prix trimestriel) / (prix mensuel * 3) * 100
            discount = Math.round(((baseMonthlyPrice * 3) - selectedPlan.unit_amount) / (baseMonthlyPrice * 3) * 100);
        } else {
            displayName = 'Monthly Subscription';
            period = 'month';
            // Calcul de la réduction: (prix mensuel de base - prix mensuel) / prix mensuel de base * 100
            discount = Math.round((baseMonthlyPrice - selectedPlan.unit_amount) / baseMonthlyPrice * 100);
        }

        displayPrice = formatPrice(selectedPlan.unit_amount);

        return { displayName, displayPrice, period, discount, basePrice };
    };

    const { displayName, displayPrice, period, discount, basePrice } = formatPlanDetails();

    const paymentElementOptions = {
        layout: {
            type: 'tabs',
            defaultCollapsed: false
        },
        paymentMethodOrder: ['card', 'paypal'],
        defaultValues: {
            billingDetails: {
                name: `${formData.firstName} ${formData.lastName}`,
                email: formData.email,
                address: {
                    line1: formData.addressLine1,
                    line2: formData.addressLine2 || undefined,
                    city: formData.city,
                    state: formData.state,
                    postal_code: formData.postalCode,
                    country: formData.country
                }
            }
        }
    };

    // Fonction de debug pour pré-remplir le formulaire
    const fillDebugData = () => {
        setFormData({
            firstName: 'Fabien',
            lastName: 'Boco',
            email: 'fabien.boco@gmail.com',
            addressLine1: '17 Allée du mont vallier',
            addressLine2: '',
            city: 'Colomiers',
            state: '',
            postalCode: '31700',
            country: 'FR',
            companyName: '',
            vatNumber: ''
        });
    };

    // Ajouter un useEffect pour remplir automatiquement en développement
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            fillDebugData();
        }
    }, []);

    return (
        <Popup onClose={onClose} className="payment-popup">
            <div className="custom-payment-popup">
                <div className="payment-columns">
                    {/* Left Column - Summary */}
                    <div className="payment-column summary-column">
                        <div className="subscription-summary">
                            <h2>Order Summary</h2>
                            <div className="plan-details">
                                <h3>{displayName}</h3>
                                <p className="price">{displayPrice} / {period}</p>
                                {discount > 0 && (
                                    <span className="discount-badge">{discount}% off</span>
                                )}
                            </div>
                            <div className="price-breakdown">
                                <div className="price-row">
                                    <span>Base price {period === 'year' ? '(yearly)' : period === 'quarter' ? '(quarterly)' : '(monthly)'}</span>
                                    <span>{formatPrice(baseMonthlyPrice * (period === 'year' ? 12 : period === 'quarter' ? 3 : 1))}</span>
                                </div>
                                {discount > 0 && (
                                    <div className="price-row">
                                        <span>Discount</span>
                                        <span>-{formatPrice(
                                            (baseMonthlyPrice * (period === 'year' ? 12 : period === 'quarter' ? 3 : 1)) - selectedPlan.unit_amount
                                        )}</span>
                                    </div>
                                )}
                                <div className="price-row total">
                                    <span>Total</span>
                                    <span>{displayPrice}</span>
                                </div>
                            </div>
                        </div>

                        <div className="premium-benefits">
                            <h3>Premium Benefits</h3>
                            <ul>
                                <li>✓ The latest OpenAI model for better content</li>
                                <li>✓ Up to 100 000 words of content generation per month</li>
                                <li>✓ YouTube to Blog article</li>
                                <li>✓ YouTube to Newsletter</li>
                                <li>✓ YouTube to Tweets/X</li>
                                <li>✓ YouTube to Linkedin</li>
                                <li>✓ YouTube video summarize tool</li>
                                <li>✓ YouTube to Custom prompt tool</li>
                                <li>✓ Multilingual Support (30+ languages)</li>
                            </ul>
                        </div>

                        <div className="social-proof">
                            <div className="users-count">
                                <span className="count">5000+</span>
                                <span>users trust us</span>
                            </div>
                            <div className="testimonial">
                                "Game-changing tool! Tripled my content output while maintaining quality. The AI understands context perfectly."
                                <span className="author">- Thomas K., Blogger</span>
                            </div>
                            <div className="trust-badges">
                                <span>🔒 Secure payment</span>
                                <span>⚡ Instant access</span>
                                <span>↩️ 7-day money back guarantee</span>
                            </div>
                        </div>
                    </div>

                    {/* Right Column - Payment Form */}
                    <div className="payment-column form-column">
                        <h2>Payment Details</h2>
                        <div className="customer-type-toggle">
                            <button 
                                className={!isCompany ? 'active' : 'button-hollow'} 
                                onClick={() => setIsCompany(false)}
                            >
                                Individual
                            </button>
                            <button 
                                className={isCompany ? 'active' : 'button-hollow'} 
                                onClick={() => setIsCompany(true)}
                            >
                                Business
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            {isCompany && (
                                <div className="form-company-section">
                                    <input
                                        type="text"
                                        name="companyName"
                                        placeholder="Company Name"
                                        value={formData.companyName}
                                        onChange={handleInputChange}
                                        required={isCompany}
                                    />
                                    <input
                                        type="text"
                                        name="vatNumber"
                                        placeholder="VAT Number"
                                        value={formData.vatNumber}
                                        onChange={handleInputChange}
                                        required={isCompany}
                                    />
                                </div>
                            )}

                            <div className="form-personal-section">
                                <div className="form-row">
                                    <input
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="addressLine1"
                                    placeholder="Address Line 1"
                                    value={formData.addressLine1}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="addressLine2"
                                    placeholder="Address Line 2 (Optional)"
                                    value={formData.addressLine2}
                                    onChange={handleInputChange}
                                />
                                <div className="form-row">
                                    <input
                                        type="text"
                                        name="city"
                                        placeholder="City"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="state"
                                        placeholder="State/Province/Region"
                                        value={formData.state}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-row">
                                    <input
                                        type="text"
                                        name="postalCode"
                                        placeholder="Postal Code"
                                        value={formData.postalCode}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <select
                                        name="country"
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map(country => (
                                            <option key={country.code} value={country.code}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <PaymentElement options={paymentElementOptions} />

                            {error && <div className="error-message">{error}</div>}

                            <button 
                                type="submit" 
                                disabled={isLoading || !stripe} 
                                className="submit-button"
                            >
                                {isLoading ? 'Processing...' : 'Subscribe Now'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {process.env.NODE_ENV === 'development' && (
                <button 
                    type="button"
                    onClick={fillDebugData}
                    style={{ 
                        position: 'absolute', 
                        top: '10px', 
                        right: '10px',
                        background: '#ff4444',
                        color: 'white',
                        border: 'none',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    Debug Fill
                </button>
            )}
        </Popup>
    );
};

export default CustomPaymentPopup; 