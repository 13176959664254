import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Toggle from '../UI/Toggle';
import LoadingButton from '../UI/LoadingButton';
import api from '../api';
import './PricingPage.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { AuthContext } from '../Login/AuthContext';
import PricingPlans from '../Pricing/PricingPlans';
import FAQ from '../UI/FAQ';
import { usePricing } from '../Payment/PricingContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CustomPaymentPopup from '../Payment/CustomPaymentPopup';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PricingPage = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const { prices, currency, loading, formatPrice } = usePricing();
    const [isYearly, setIsYearly] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [paymentData, setPaymentData] = useState(null);
    const navigate = useNavigate();

    // Initialiser selectedPlan une fois que les prix sont chargés
    React.useEffect(() => {
        if (prices && prices.monthly) {
            setSelectedPlan(prices.monthly.id);
        }
    }, [prices]);

    // Ajouter ce code après le useEffect qui initialise selectedPlan
    React.useEffect(() => {
        if (prices) {
            console.log('Structure complète des prix:', JSON.stringify(prices, null, 2));
        }
    }, [prices]);

    const handleSubmit = async (id) => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            setCheckoutLoading(true);
            
            // Trouver le plan sélectionné
            const selectedPriceObj = Object.values(prices).find(price => price.id === id);
            
            if (id === process.env.REACT_APP_LTD_PRICE_ID) {
                // Gestion spéciale pour les offres à vie
                const response = await api.post(`/ltd_checkout/${id}`);
                window.location.href = response.data.checkout_url;
            } else {
                // Utiliser le nouveau système de paiement pour les abonnements
                const response = await api.post("/create-payment-intent", {
                    plan: {
                        id: id,
                        currency: currency
                    }
                });
                
                const { clientSecret, subscriptionId } = response.data;
                
                setShowPaymentPopup(true);
                setPaymentData({
                    clientSecret,
                    subscriptionId,
                    plan: selectedPriceObj
                });
            }
        } catch (error) {
            console.error('Erreur pendant le checkout:', error);
        } finally {
            setCheckoutLoading(false);
        }
    };

    const pricingFaqQuestions = [
        {
            icon: 'fa-key',
            question: '[Lifetime Deal] Is it complicated to get an OpenAI account and API key?',
            answer: 'Not at all! Setting up an OpenAI account is a straightforward and free process. Once you\'ve registered, you\'ll have easy access to your API key. OpenAI\'s user-friendly interface makes it simple to get started, even if you\'re not tech-savvy.'
        },
        {
            icon: 'fa-dollar-sign',
            question: '[Lifetime Deal] How much will the OpenAI API cost me?',
            answer: 'The cost depends on the length of the video you\'re processing and the size of the generated content. It will be your responsibility to monitor your API usage, which you can easily do through your OpenAI dashboard at OpenAI Usage.'
        },
        {
            icon: 'fa-rotate-left',
            question: '[Lifetime Deal] Will you refund me if I don\'t like it?',
            answer: 'Absolutely, I will provide you with a no-questions-asked refund as long as your request is made within 30 days of the purchase.'
        }
    ];

    if (loading) {
        return (
            <>
                <Navbar />
                <div className="pricing-page">
                    <div className="section">
                        <div className="section-header">
                            <h1>PRICING</h1>
                            <h2>Loading prices...</h2>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className="pricing-page">
                <div className="section">
                    <div className="section-header">
                        <h1>PRICING</h1>
                        <h2>Choose the plan that's right for you</h2>
                        <p>Transform your YouTube content into various formats with our AI-powered tools</p>
                    </div>

                    <PricingPlans
                        isYearly={isYearly}
                        setIsYearly={setIsYearly}
                        selectedPlan={selectedPlan}
                        onPlanSelect={setSelectedPlan}
                        onSubmit={handleSubmit}
                        checkoutLoading={checkoutLoading}
                        user={user}
                        className="pricing-page-plans"
                        prices={prices}
                        formatPrice={formatPrice}
                    />
                </div>

                <FAQ 
                    title={<>Frequently Asked <span className="highlight">Questions</span></>}
                    questions={pricingFaqQuestions} 
                />

            </div>
            <Footer />

            {showPaymentPopup && paymentData && (
                <Elements 
                    stripe={stripePromise} 
                    options={{ clientSecret: paymentData.clientSecret }}
                >
                    <CustomPaymentPopup 
                        selectedPlan={paymentData.plan}
                        onClose={() => setShowPaymentPopup(false)}
                        clientSecret={paymentData.clientSecret}
                        subscriptionId={paymentData.subscriptionId}
                        baseMonthlyPrice={prices.monthly?.unit_amount}
                    />
                </Elements>
            )}
        </>
    );
};

export default PricingPage; 