import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '../UI/Popup'; // Assure-toi que ce composant Popup existe dans ton projet
import api from '../api'; // Pour envoyer les requêtes à l'API
import './PricingPopup.css'; // Fichier CSS spécifique pour ce composant
import LoadingButton from '../UI/LoadingButton';
import Toggle from '../UI/Toggle';
import { AuthContext } from '../Login/AuthContext';
import PricingPlans from '../Pricing/PricingPlans';
import { usePricing } from '../Payment/PricingContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CustomPaymentPopup from '../Payment/CustomPaymentPopup';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

/* global gtag_report_conversion */
const PricingPopup = ({ onClose }) => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const { prices, currency, loading, formatPrice } = usePricing();
    const [isYearly, setIsYearly] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [paymentData, setPaymentData] = useState(null);
    const navigate = useNavigate();

    // Initialiser selectedPlan une fois que les prix sont chargés
    React.useEffect(() => {
        if (prices && prices.monthly) {
            setSelectedPlan(prices.monthly.id);
        }
    }, [prices]);

    const handleSubmit = async (id) => {
        if (!isAuthenticated) {
            window.location.href = '/login';
            return;
        }

        try {
            setCheckoutLoading(true);
            
            // Trouver le plan sélectionné
            const selectedPriceObj = Object.values(prices).find(price => price.id === id);
            
            if (id === process.env.REACT_APP_LTD_PRICE_ID) {
                // Gestion spéciale pour les offres à vie
                const response = await api.post(`/ltd_checkout/${id}`);
                gtag_report_conversion();
                window.location.href = response.data.checkout_url;
            } else {
                // Utiliser le nouveau système de paiement pour les abonnements
                const response = await api.post("/create-payment-intent", {
                    plan: {
                        id: id,
                        currency: currency
                    }
                });
                
                const { clientSecret, subscriptionId } = response.data;
                
                gtag_report_conversion();
                setShowPaymentPopup(true);
                setPaymentData({
                    clientSecret,
                    subscriptionId,
                    plan: selectedPriceObj
                });
            }
        } catch (error) {
            console.error('Erreur pendant le checkout:', error);
        } finally {
            setCheckoutLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="pricing-container">
                <Popup onClose={onClose}>
                    <h2>Loading prices...</h2>
                </Popup>
            </div>
        );
    }

    return (
        <div className="pricing-container">
            <Popup onClose={onClose}>
                <h2>Choose Your Plan</h2>
                <PricingPlans
                    isYearly={isYearly}
                    setIsYearly={setIsYearly}
                    selectedPlan={selectedPlan}
                    onPlanSelect={setSelectedPlan}
                    onSubmit={handleSubmit}
                    checkoutLoading={checkoutLoading}
                    user={user}
                    prices={prices}
                    formatPrice={formatPrice}
                />
            </Popup>

            {showPaymentPopup && paymentData && (
                <Elements 
                    stripe={stripePromise} 
                    options={{ clientSecret: paymentData.clientSecret }}
                >
                    <CustomPaymentPopup 
                        selectedPlan={paymentData.plan}
                        onClose={() => setShowPaymentPopup(false)}
                        clientSecret={paymentData.clientSecret}
                        subscriptionId={paymentData.subscriptionId}
                        baseMonthlyPrice={prices.monthly?.unit_amount}
                    />
                </Elements>
            )}
        </div>
    );
};

export default PricingPopup;
