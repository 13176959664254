import React, { useState, useEffect, useRef } from 'react';

const MarketingCarousel = () => {
    const [position, setPosition] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const carouselRef = useRef(null);
    const animationRef = useRef(null);
    
    const items = [
        {
            image: '/images/carousel/content.png',
            title: 'Content Marketing',
            price: '$100-200 Per Content Piece'
        },
        {
            image: '/images/carousel/seo.png',
            title: 'SEO',
            price: '$499-999 Per Month'
        },
        {
            image: '/images/carousel/ppc.png',
            title: 'PPC',
            price: '$300-500 Per Campaign'
        },
        {
            image: '/images/carousel/email.png',
            title: 'Email Marketing',
            price: '$499 or more Per Month'
        },
        {
            image: '/images/carousel/influencer.png',
            title: 'Influencer Marketing',
            price: '$499-999 Per Promo'
        },
        {
            image: '/images/carousel/mobile.png', 
            title: 'Mobile Marketing',
            price: '$300-500 Per Campaign'
        }
    ];
    
    // Clone items for infinite effect
    const allItems = [...items, ...items, ...items]; // Triple the items
    
    // Animation function for continuous scrolling
    const animate = () => {
        if (carouselRef.current) {
            setPosition(prev => {
                // If we've scrolled too far, reset to beginning but maintain visual position
                if (prev <= -100 * items.length) {
                    return prev + 100 * items.length;
                }
                return prev - 0.05; // Speed of continuous scrolling
            });
        }
        animationRef.current = requestAnimationFrame(animate);
    };
    
    // Start the animation when component mounts
    useEffect(() => {
        animationRef.current = requestAnimationFrame(animate);
        
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);
    
    // Handle manual navigation
    const handleNavigation = (direction) => {
        if (isTransitioning) return;
        
        setIsTransitioning(true);
        const itemWidth = 100 / 4; // Show 4 items at once
        
        setPosition(prev => {
            if (direction === 'next') {
                return prev - itemWidth;
            } else {
                return prev + itemWidth;
            }
        });
        
        setTimeout(() => {
            setIsTransitioning(false);
        }, 500);
    };
    
    return (
        <div className="alp-carousel-container">
            <div className="alp-carousel-track" ref={carouselRef} style={{ overflow: 'hidden' }}>
                <div 
                    className="alp-carousel" 
                    style={{ 
                        transform: `translateX(${position}%)`,
                        transition: isTransitioning ? 'transform 0.5s ease' : 'none',
                        display: 'flex'
                    }}
                >
                    {allItems.map((item, index) => (
                        <div 
                            className="alp-carousel-item" 
                            key={index}
                            style={{ flex: '0 0 25%' }} // 4 items per view
                        >
                            <img src={item.image} alt={item.title} />
                            <h3 className="alp-carousel-title">{item.title}</h3>
                            <div className="alp-carousel-price">{item.price}</div>
                        </div>
                    ))}
                </div>
            </div>
            
            <button className="alp-carousel-nav alp-carousel-prev" onClick={() => handleNavigation('prev')}>
                ‹
            </button>
            <button className="alp-carousel-nav alp-carousel-next" onClick={() => handleNavigation('next')}>
                ›
            </button>
        </div>
    );
};

export default MarketingCarousel; 